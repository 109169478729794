import React from 'react'

import { IoClose } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";

function JoinSuccessCard({data, handleModal}) {
    

    return (
        <div className='joinsuccesscard'>
            <div className='joinsuccesscard__card'>

                <p className='joinsuccesscard__card__closebtn' style={{ width: "100%" }}>
                    <IoClose size="35px" className='joinsuccesscard__card__closebtn__icon' onClick={()=> handleModal(false, "") } />
                 </p>

                <div className='joinsuccesscard__card__success'>
                    <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}><img src="./assets/images/successicon.png" alt="" /></span>
                </div>

                <p className='joinsuccesscard__card__title'>We have added you to the waiting list!</p>

                <div className="joinsuccesscard__card__invite__card">
                    <p>Invite your network to join waitlist and earn exclusive rewards when they sign up</p>

                    <div className="ps-3 joinsuccesscard__card__invite__card__input ">
                        <span className='me-2' style={{ fontSize: "1.2rem", fontWeight: "bold" }}><MdOutlineMailOutline /></span>
                        <input type="email" value={data? data : ""} placeholder="youremail@email.com" required readOnly />
                    </div>
                </div>

                <p className='joinsuccesscard__card__followtext'>
                    Follow us on social media for sneak peeks, updates, and behind-the-scene glimpses into the NFTs revolution.
                </p>
            </div>
        </div>
    )
}

export default JoinSuccessCard