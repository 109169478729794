import React, { useEffect } from 'react'
import { useTheme } from '../../context/ThemeProvider'
import AOS from "aos"
import "aos/dist/aos.css"

function WhyJoinArtbok() {

    const { theme } = useTheme()

    useEffect(() => {
        AOS.init({
            duration: 700
        })
    })

    return (
        <div className='whyjoinartbok' style={{ width: "100%", maxWidth: "1200px" }}>
            <div>

                <div className='mb-5 d-flex justify-content-center ' style={{}}>
                    <div className='' style={{ display: "inline-block", position: "relative" }}>
                        <h4 className='text-center whyjoinartbok__header'>Here's why you should join Artbok</h4>
                        {
                            theme === "light" ?
                                <span className='' style={{ display: "inline-block", position: "absolute", right: "-5em", top: "15px" }}><img src="./assets/images/curlyarrow.png" alt="" style={{ maxWidth: "77px" }} /></span> :
                                <span className='' style={{ display: "inline-block", position: "absolute", right: "-5em", top: "15px" }}><img src="./assets/images/curlyarrowlight.png" alt="" style={{ maxWidth: "77px" }} /></span>
                        }

                    </div>
                </div>

                <div>

                    <div className='d-flex justify-content-center align-items-start gap-4 whyjoinartbok__content' style={{ marginBottom: "5rem" }}>
                        <div className='whyjoinartbok__content__details' data-aos="fade-right">
                            <p><img src="./assets/images/nigmap.png" alt="" style={{ width: "48px" }} /></p>
                            <p className='whyjoinartbok__content__details__title'>Unlock Exclusive NFT Collections</p>
                            <p className='whyjoinartbok__content__details__text'>Unlock Exclusive NFT Collections: Access exclusive collections that redefine digital art boundaries.</p>
                        </div>

                        <div className='whyjoinartbok__content__card' data-aos="fade-left">
                            <img src="./assets/images/nftcard.png" alt="" style={{ maxWidth: "500px" }} />
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-start gap-4 whyjoinartbok__content' style={{ marginBottom: "5rem" }}>
                        <div className='whyjoinartbok__content__details' data-aos="fade-left">
                            <p><img src="./assets/images/nigmap.png" alt="" style={{ width: "48px" }} /></p>
                            <p className='whyjoinartbok__content__details__title'>Personalized Fashion Items</p>
                            <p className='whyjoinartbok__content__details__text'>Our print-on-demand market place offers customizable pieces for expressing your individuality</p>
                        </div>

                        <div className='whyjoinartbok__content__card' data-aos="fade-right">
                            <img className='' src="./assets/images/nftcard2.png" alt="" style={{ maxWidth: "700px" }} />
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-start gap-4 whyjoinartbok__content' style={{ marginBottom: "5rem" }}>
                        <div className='whyjoinartbok__content__details' data-aos="fade-right">
                            <p><img src="./assets/images/nigmap.png" alt="" style={{ width: "48px" }} /></p>
                            <p className='whyjoinartbok__content__details__title'>Unlock Exclusive NFT Collections</p>
                            <p className='whyjoinartbok__content__details__text'>Unlock Exclusive NFT Collections: Access exclusive collections that redefine digital art boundaries.</p>
                        </div>

                        <div className='mt-4 whyjoinartbok__content__card' data-aos="fade-left">
                            <img src="./assets/images/nftcard3.png" alt="" style={{ maxWidth: "500px" }} />
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-start gap-4 whyjoinartbok__content'>
                        <div className='whyjoinartbok__content__details' data-aos="fade-left">
                            <p><img src="./assets/images/nigmap.png" alt="" style={{ width: "48px" }} /></p>
                            <p className='whyjoinartbok__content__details__title'>Secure Blockchain Transactions</p>
                            <p className='whyjoinartbok__content__details__text'>
                                Our platform ensures transaction integrity and authenticity, boosting confidence in your NFT purchases.
                            </p>
                        </div>

                        <div className='whyjoinartbok__content__card' data-aos="fade-right">
                            <img src="./assets/images/nftcard4.png" alt="" style={{ maxWidth: "500px" }} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default WhyJoinArtbok