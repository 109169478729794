import React, { useState } from 'react'
import { FaUserPen } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";
import { GoArrowRight } from "react-icons/go";
import axios from "axios"
import Swal from "sweetalert2"  

function JoinUsForm({ handleModal }) {

  const [user, setUser] = useState({ fullName:"", email: "", userType: "",})
  const [loading, setLoading] = useState(false)

  function handleInput(e) {
    const {name, value} = e.target
    setUser({...user, [name]:value})
}

async function handleSubmit(e){
  e.preventDefault()
  setLoading(true)

  console.log({user})

  const {email} = user
  try{
      if(!email){
          setLoading(false)
          return Swal.fire({
              title: "Missing Field",
              text: "Field empty! Enter email",
              icon:"warning"
          })
      }

      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ ;
      const test = regex.test(email)
      if(!test){
        setLoading(false)
        return Swal.fire({
            title: "Invalid email",
            icon:"error"
        })
      }

      const data = user
      const url = "https://artbokprelaunchbe.onrender.com/v1/waitlists"
      const response = await axios.post(url, data)

      if(response.data.msg === "Email already exist!"){
          setLoading(false)
          return Swal.fire({
              title: "Error",
              text: "Email already exist",
              icon:"error"
          })
      }

      //Success Modal
      handleModal("show", email)
      setUser({...user, fullName:"", email:"", userType:""})
      setLoading(false)

  }
  catch(err){
      setLoading(false)
      return Swal.fire({
          title: "Error",
          text: "Something went wrong!",
          icon:"warning"
      })
  }
}


  return (
    <div className='joinusform'>

      <p className='joinusform__text'>Join our vibrant creator community, share insights, and collaborate with fellow artists.</p>

      <p className='joinusform__signuptext'>Sign up to be one of the first to use ArtBok.</p>

      <div className='joinusform__form'>
        <p className='joinusform__form__usercount'>+2,000 Creators Joined</p>

        <div style={{ marginTop: "-1em" }}>
          <img src="./assets/images/joinedgroup.png" alt="" />
        </div>


        <form action="" onSubmit={handleSubmit} className='border'>

          {/* <div className='joinusform__form__entry mb-5'>
            <label htmlFor="" className='mb-2'>FullName</label>
            <div className="ps-3 joinusform__form__entry__input border">
              <span className='me-2' style={{ fontSize: "1.2rem", fontWeight: "bold" }}><FaUserPen /></span>
              <input type="text" value={user.fullName} placeholder="Tell us your name" name="fullName" required onChange={handleInput}/>
            </div>
          </div> */}

          <div className='joinusform__form__entry mb-4'>
            <label htmlFor="" className='mb-2'>Email Address</label>
            <div className="ps-3 joinusform__form__entry__input border">
              <span className='me-2' style={{ fontSize: "1.2rem", fontWeight: "bold" }}><MdOutlineMailOutline /></span>
              <input type="text" value={user.email} placeholder="Enter your email address" name="email" required onChange={handleInput}/>
            </div>
          </div>

          <div className='d-flex justify-content-start align-items-center gap-3 mb-3 joinusform__form__entry__input__checkbox' style={{}}>
            <input type="radio" id='usertype' name='userType' value="creator" checked={user.userType==="creator"} required onChange={handleInput} />
            <label htmlFor="usertype">Join as a creator</label>
          </div>

          <div className='d-flex justify-content-start align-items-center gap-3 mb-3 joinusform__form__entry__input__checkbox'>
            <input type="radio" id='creatortype' name='userType' value="user" checked={user.userType==="user"} required onChange={handleInput} />
            <label htmlFor="creatortype" className='mb-2'>Join as a user</label>
          </div>

          <button type='submit'><img src="./assets/images/solar_star-fall-outline.png" alt="" style={{ width: "2.2rem", marginRight: ".5em", marginTop: ".3em" }} />{loading? "processing..." : "Join waitlist"}  <span className='me-4'><GoArrowRight /></span></button>

        </form>
      </div>
    </div>
  )
}

export default JoinUsForm