import React from 'react'
import "./styles/creatorswaitlist.css"

import PartneringBrands from '../../components/PartneringBrands'
import WhyJoinUs from '../../components/creatorsPageComponents/WhyJoinUs'
import JoinUsForm from '../../components/creatorsPageComponents/JoinUsForm'
import HeroSection from '../../components/creatorsPageComponents/HeroSection'

function CreatorsWaitlist({ handleModal }) {
  return (
    <div className='creatorswaitlist bg-blue'>

      
        {/* HERO SECTION */}
        <div className='creator__page__hero__section__container'>
          <HeroSection handleModal={handleModal} />
        </div>

        {/* PARTNERING BRANDS */}
        <div className='mt-4'>
          <PartneringBrands />
        </div>



        {/* WHY JOIN US */}
        <div className='mt-5 px-4 d-flex justify-content-center align-items-center'>
          <WhyJoinUs />
        </div>

      {/* JOIN US FORM */}
      <div id='joinwaitlistform' className='d-flex justify-content-center align-items-center joinusform__container' style={{ marginTop: "12rem", paddingBottom: "5em" }}>
        <JoinUsForm handleModal={handleModal} />
      </div>

    </div>
  )
}

export default CreatorsWaitlist