
const adsData = [
    {
        id:"1",
        title: "Connect & Collaborate",
        description: "Connect with other creative minds and collaborate to create digital masterpieces, then turn these masterpieces with Artbok into wearable statement.",
        image: "./assets/images/collaborate.png",
        icon:"./assets/images/collabicon1.png"
    },

    {
        id:"2",
        title: "Showcase Your Talent",
        description: "Showcase your art in our NFT marketplace, gaining exposure. Seamlessly integrate with our print-on-demand platform for endless design possibilities.",
        image: "./assets/images/collaborate2.png",
        icon:"./assets/images/collabicon2.png"
    },

    {
        id:"3",
        title: "Diversify Your Revenue",
        description: "Blend art and fashion for diverse revenue. Set terms and prices for NFTs and collaborations, watching your earnings grow with your creativity.",
        image: "./assets/images/collaborate3.png",
        icon: "./assets/images/collabicon3.png"
    },

    {
        id:"4",
        title: "Early Access Benefits",
        description: "Early ARTBOK creators get priority access to our exclusive NFT marketplace and early artist features. So secure your spot and kickstart your journey as a pioneering creator with us!",
        image: "./assets/images/collaborate4.png",
        icon: "./assets/images/collabicon4.png"
    },
]

export default adsData