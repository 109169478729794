import React, { useEffect, useState } from 'react'
import adsData from "../../data/WhyJoinUsData"

function WhyJoinUs() {
    const [currentAd, setCurrentAd] = useState("")

    const handleSlides = (e) => {
        const id = e.target.id
        const ad = adsData.find(ad => ad.id === id)
        setCurrentAd(ad)
    }

    useEffect(() => {
        setCurrentAd(adsData[0])

        // const intervalId = setInterval(()=>{
        //     // console.log("Logs after 2 sec.")

        //     setCurrentAd(adsData[count])
        //     setCount(2)
        //     console.log(count)

        // }, 2000)

        // // Cleanup function to clear the interval when the component unmounts
        // return ()=> clearInterval(intervalId)

    }, [])

    return (
        <div className='whyjoinus'>
            <div className=''>
                <h1 className='whyjoinus__header mb-5 text-center'>
                    Here's why you should <span>join us!</span>
                </h1>

                <div className='whyjoinus__btngroups'>
                    <button className={currentAd.id === "1" ? "whyjoinus__btngroups__activebtn" : ""} id='1' onClick={handleSlides}>Collaborate</button>
                    <button className={currentAd.id === "2" ? "whyjoinus__btngroups__activebtn" : ""} id='2' onClick={handleSlides}>Showcase Your Talent</button>
                    <button className={currentAd.id === "3" ? "whyjoinus__btngroups__activebtn" : ""} id='3' onClick={handleSlides}>Diversify Your Revenue</button>
                    <button className={currentAd.id === "4" ? "whyjoinus__btngroups__activebtn" : ""} id='4' onClick={handleSlides}>Early Access Benefit</button>
                </div>

                <div className='whyjoinus__content'>
                    <div className=''>
                        <div className='d-flex align-items-center gap-3 whyjoinus__content__title'>
                            <div style={{ width: "45px" }}><img src={currentAd.icon} alt="" /></div>
                            {currentAd.title}</div>
                        <p className='whyjoinus__content__description'>
                            {currentAd.description}
                        </p>
                    </div>

                    <div className='whyjoinus__content__image' stye={{ width: "100%", maxWidth: "300px" }}>
                        <img src={currentAd.image} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyJoinUs