import React from 'react'
import "./styles/footbar.css"

import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";

function Footbar() {
  return (
    <div className='footbar'>
      <div className='py-4 px-3 d-flex justify-content-center align-items-center flex-column'>

        <div className='d-flex justify-content-center align-items-center gap-5 footbar__group'>
          <p className='footbar__group__btn'>Follow Us</p>

          <span className='footbar__group__icons'><FaXTwitter size="25px" style={{cursor:"pointer"}}/></span>
          <span className='footbar__group__icons'><FaDiscord size="25px" style={{cursor:"pointer"}}/></span>
          <span className='footbar__group__icons'><FaFacebook size="25px" style={{cursor:"pointer"}}/></span>
        </div>

        <p className='mt-4'>ARTBOK 2024</p>

      </div>
    </div>
  )
}

export default Footbar