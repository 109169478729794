import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"

// BOOSTRAP AND CUSTOM STYLES
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.min.js"
import "./assets/styles/styles.css"

// COMPONENTS
import UsersWaitlist from './views/userswaitlist/UsersWaitlist'
import CreatorsWaitlist from './views/creatorswaitlist/CreatorsWaitlist'
import Navbar from './components/Navbar/Navbar'
import Footbar from './components/Footbar/Footbar.jsx'
import JoinSuccessCard from './components/JoinSuccessCard.jsx'
import Preloader from './components/Preloader.jsx'
import { ThemeProvider } from './context/ThemeProvider.jsx'

function App() {

  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState("")
  const [showPreloader, setShowPreloader] = useState(true)


  function handleModal(state, email) {
    setModalData(email)
    setShowModal(state === "show" ? true : false)
  }

  useEffect(() => {
    setTimeout(() => {
      setShowPreloader(false)
    }, 4000)
  })

  return (
    <BrowserRouter>
      <ThemeProvider>
        <div>
          {/* Navbar */}
          <Navbar />

          {/* {showPreloader && <Preloader />} */}

          {showModal && <JoinSuccessCard data={modalData} handleModal={handleModal} />}

          <Routes>
            <Route path='/' element={<UsersWaitlist handleModal={handleModal} />} />
            <Route path='/creators' element={<CreatorsWaitlist handleModal={handleModal} />} />
          </Routes>

          {/* Footbar */}
          <Footbar />
        </div>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App