import React, { useState } from 'react'
import { MdOutlineMailOutline } from "react-icons/md";
import axios from "axios"
import Swal from "sweetalert2"

function JoinArtbobUsers({handleModal}) {

    const [user, setUser] = useState({email:"", userType:"user"})
    const [loading, setLoading] = useState(false)

    function handleInput(e) {
        const email = e.target.value
        setUser({...user, email:email})
    }

    async function handleSubmit(e){
        e.preventDefault()
        setLoading(true)

        const {email} = user
        try{
            if(!email){
                setLoading(false)
                return Swal.fire({
                    title: "Missing Field",
                    text: "Field empty! Enter email",
                    icon:"warning"
                })
            }
    
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ ;
            const test = regex.test(email)
            if(!test){
                return alert("Invalid email")
            }

            const data = user
            const url = "https://artbokprelaunchbe.onrender.com/v1/waitlists"
            const response = await axios.post(url, data)

            if(response.data.msg === "Email already exist!"){
                setLoading(false)
                return Swal.fire({
                    title: "Error",
                    text: "Email already exist",
                    icon:"error"
                })
            }

            //Success Modal
            handleModal("show", email)
            setUser({...user, email:""})
            setLoading(false)

        }
        catch(err){
            setLoading(false)
            return Swal.fire({
                title: "Error",
                text: "Something went wrong!",
                icon:"warning"
            })
        }
    }



    return (
        <div>
            <div className='d-flex justify-content-center align-items-center flex-column text-center joinartbokusers' style={{paddingBottom:"14em"}}>

                <h2 className='mb-4' style={{fontSize:"2.8rem"}}>Ready to join ArtBok community?</h2>
                <p className='mb-3 joinartbokusers__joincount__signuptext'>Sign up to be one of the first to use ArtBok.</p>
                <div className=''>
                    <p className='mb-3 joinartbokusers__joincounttext'>+2,000 Creators Joined</p>
                    <div className='' style={{maxWidth:"12rem", margin:"auto"}}><img src="./assets/images/joinedgroup.png" alt="" /></div>
                </div>

                <div className='mt-5 userspagehero__herocontent__join__form' style={{ width: "100%" }}>
                    <form action="" onSubmit={handleSubmit} className='d-flex justify-content-center align-items-center gap-3'>
                        <div className="ps-3 userspagehero__herocontent__join__form__input">
                            <span className='me-2' style={{ fontSize: "1.2rem", fontWeight: "bold" }}><MdOutlineMailOutline /></span>
                            <input type="email" value={user.email} placeholder="Enter email" required onChange={handleInput} />
                        </div>

                        <button type='submit'>{loading? "processing..." : "Join waitlist"}</button>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default JoinArtbobUsers