import React from 'react'
import "./styles/userswaitlist.css"
import UserPageHero from '../../components/userspagecomponents/UserPageHero'
import PartneringBrands from '../../components/PartneringBrands'
import WhyJoinArtbok from '../../components/userspagecomponents/WhyJoinArtbok'
import JoinArtbobUsers from '../../components/userspagecomponents/JoinArtbobUsers'


function UsersWaitlist({handleModal}) {
  return (
    <div className='userswaitlist' >

      {/* HERO SECTION */}
      <UserPageHero handleModal={handleModal}/>



      {/* PARTNERING BRANDS SECTION  */}
      <div className='mt-4'>
        <PartneringBrands />
      </div>


      {/* WHY JOIN ARTBOK SECTION */}
      <div className='mt-5 px-4 d-flex justify-content-center align-items-center'>
        <WhyJoinArtbok />
      </div>


      {/* READY TO JOIN ARTBOK SECTION */}
      <div className='d-flex justify-content-center align-items-center' style={{marginTop:"12rem"}}>
        <JoinArtbobUsers handleModal={handleModal}/>
      </div>


    </div>
  )
}

export default UsersWaitlist