import React, { useState } from 'react'
import "./styles/navbar.css"
import { NavLink } from 'react-router-dom'
import { useTheme } from '../../context/ThemeProvider';

import { MdOutlineWbSunny } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";

function Navbar() {

  const [light, setLight] = useState(!true)
  const { theme, toggleTheme } = useTheme()

  function handleToggleTheme(){
    setLight(!light)
    toggleTheme()
  }

  return (
    <nav className='navigbar'>

      <div className='navigbar__logo' style={{ width: "clamp(100px, 1vw, 150px)" }}>
        {
          theme==="light"?
          <img src="./assets/images/artboklogo.png" alt="" style={{ maxWidth: "100%" }} />:
          <img src="./assets/images/artboklogolight.png" alt="" style={{ maxWidth: "100%" }} />
        }
        
      </div>

      <ul className='navigbar__waitlist__links'>
        <li>
          <NavLink to="/" className={({ isActive }) => isActive ? 'navigbar__waitlist__links__users navigbar__waitlist__links__active' : 'navigbar__waitlist__links__users'}>Users Waitlist</NavLink>
        </li>

        <li>
          <NavLink to="/creators" className={({ isActive }) => isActive ? 'navigbar__waitlist__links__creators navigbar__waitlist__links__active' : 'navigbar__waitlist__links__creators'}>Creators Waitlist</NavLink>
        </li>
      </ul>

      <div className='d-flex gap-2'>
        <div className='navigbar__theme__btns border' onClick={handleToggleTheme}>
          <span className={!light ? 'navigbar__theme__btns__active ' : ""}><MdDarkMode size="20px" /> </span>
          <span className={light ? 'navigbar__theme__btns__active' : ""}><MdOutlineWbSunny size="20px" /></span>
        </div>

        <div class="dropdown navigbar__waitlist__link__mobile">
          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <AiOutlineMenu />
          </button>
          <ul class="dropdown-menu">
            <li>
              <NavLink to="/" className='navigbar__waitlist__links__users dropdown-item dropdown-item'>Users Waitlist</NavLink>
            </li>

            <li>
              <NavLink to="/creators" className='navigbar__waitlist__links__creators dropdown-item'>Creators Waitlist</NavLink>
            </li>
          </ul>
        </div>
      </div>


    </nav >
  )
}

export default Navbar