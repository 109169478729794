import React from 'react'
import { useTheme } from '../context/ThemeProvider'

function PartneringBrands() {
    
    const {theme} = useTheme()

    return (
        <div className='partneringbrands d-flex justify-content-center align-items-center' style={{marginBottom:"6em"}}>
            <div className='d-flex justify-content-center align-items-center flex-column px-3' style={{width:"100%", maxWidth:"1200px"}}>
                <p className='mb-3 mt-4' style={{fontSize:"2rem"}}>Brands partnering with us</p>
                <div className="partneringbrands__brands">
                    {/* <p><img src="./assets/images/frame1.png" alt="" /></p>
                    <p><img src="./assets/images/frame2.png" alt="" /></p>
                    <p><img src="./assets/images/frame3.png" alt="" /></p>
                    <p><img src="./assets/images/frame4.png" alt="" /></p> */}
                    {
                        theme==="light"?
                        <p><img src="./assets/images/partneringbrands.png" alt="" /></p>:
                        <p><img src="./assets/images/partneringbrandslight.png" alt="" /></p>
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default PartneringBrands